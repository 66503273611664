const CartEufy = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path d="M20 0H0V20H20V0Z" fill="white" fillOpacity="0.01" />
      <path
        d="M2.25115 16.5677C2.25038 16.5815 2.25 16.5954 2.25 16.6093C2.25 17.4275 2.89183 18.1505 3.75556 18.1505H15.8444C16.7082 18.1505 17.35 17.4274 17.35 16.6093C17.35 16.5954 17.3496 16.5815 17.3488 16.5677L16.7932 6.55891L16.5933 2.95891C16.5712 2.56146 16.2425 2.25049 15.8444 2.25049H3.75556C3.3575 2.25049 3.02878 2.56146 3.00671 2.95891L2.25115 16.5677Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8218 7.00146C12.8218 8.68093 11.4687 10.0424 9.79958 10.0424C8.13046 10.0424 6.77734 8.68093 6.77734 7.00146"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default CartEufy
